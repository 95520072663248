<template>
  <div class="bac">
    <div class="main">
      <div class="first_line">
        <p>账户名：</p>
        <input type="text">
        <p>预留手机号：</p>
        <input style="padding-left:240px;color: #acaeab" type="text" value="| 获取验证码">
      </div>
      <div class="second_line">
        <p>账户名：</p>
        <input type="text">
        <p>短信验证码：</p>
        <input type="text">
      </div>
      <div class="third_line">
        <p>设置支付密码：</p>
        <input type="text">
        <p>开户行：</p>
        <input type="text">
      </div>
      <div class="fourth_line">
        <p>确认密码：</p>
        <input type="text">
      </div>
      <button>保存</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountManage"
}
</script>

<style scoped>
.bac{
  /*float: left;*/
  width: 1920px;
  /*height: 1080px;*/
  padding-left: 358px;
  padding-top: 65px;
  background-color: #f4f4f4;
}

.main{
  position: relative;
  width: 1462px;
  height: 476px;
  background-color: #ffffff;
  padding-top: 80px;
}

.main p{
  float: left;
  font-size: 18px;
  color: #212121;
}

.main input{
  display: block;
  float: left;
  width: 318px;
  height: 32px;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
}

.main .first_line{
  width: 1462px;
  height: 32px;
  margin-left: 217px;
  margin-bottom: 15px;
}

.main .first_line input{
  margin-right: 185px;
}

.main .second_line{
  width: 1462px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: 217px;
}

.main .second_line input{
  margin-right: 186px;
}

.main .third_line{
  width: 1462px;
  height: 32px;
  margin-bottom: 15px;
  margin-left: 165px;
}

.main .third_line input{
  margin-right: 222px;
}

.main .fourth_line{
  width: 1462px;
  height: 32px;
  margin-left: 202px;
  margin-bottom: 15px;
}

.main .fourth_line input:nth-of-type(1){
  width: 166px;
  height: 120px;
  margin-right: 369px;
}

.main button{
  position: absolute;
  right: 663px;
  bottom: 82px;
  width: 180px;
  height: 45px;
  background-color: #ec414d;
  font-size: 20px;
  color: #ffffff;
  border-radius: 5px;
}

</style>